
html {
  background-color: black;

  /* Dark scroll bar */
  color-scheme: dark;
}

.App {
  text-align: center;
}

/* Removes recaptcha white background */
iframe {
  border-radius: 5px;
  width: 302px;
  height: 76px;
}

/*Text animated underline*/
#moreInfoAvailableSoon:after {
  /*background: none repeat scroll 0 0 transparent;*/
  bottom: 0;
  content: "";
  display: block;
  height: 1px;
  left: 50%;
  position: absolute;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  background: #ffff;
  width: 0;
}

#moreInfoAvailableSoon:hover:after {
  width: 100%;
  left: 0;
}


/*.App-logo {*/
/*  height: 40vmin;*/
/*  pointer-events: none;*/
/*}*/

/*@media (prefers-reduced-motion: no-preference) {*/
/*  .App-logo {*/
/*    animation: App-logo-spin infinite 20s linear;*/
/*  }*/
/*}*/


/*.App-header {*/
/*  background-color: #282c34;*/
/*  min-height: 100vh;*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  align-items: center;*/
/*  justify-content: center;*/
/*  font-size: calc(10px + 2vmin);*/
/*  color: white;*/
/*}*/

/*.App-link {*/
/*  color: #61dafb;*/
/*}*/

/*@keyframes App-logo-spin {*/
/*  from {*/
/*    transform: rotate(0deg);*/
/*  }*/
/*  to {*/
/*    transform: rotate(360deg);*/
/*  }*/
/*}*/



